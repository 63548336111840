@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-alice-carousel/lib/alice-carousel.css';

@font-face {
  font-family: 'JUST Sans Bold';
  src: url('https://public-static-mobilithas.s3.amazonaws.com/fonts/just-sans/JUSTSansExBold.woff2')
    format('woff');
}

@font-face {
  font-family: 'JUST Sans';
  src: url('https://public-static-mobilithas.s3.amazonaws.com/fonts/just-sans/JUSTSansRegular.woff2')
    format('woff');
}

@font-face {
  font-family: 'Nesiota';
  src: url('https://public-static-mobilithas.s3.amazonaws.com/fonts/nesiota/NesiotaRegular.woff')
    format('woff');
}

body {
  margin: 0;
}
